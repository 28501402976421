.MuiDataGrid-root {
  /* border: 0.1px solid rgba(224, 224, 224, 0.8) !important; */
  border: none !important;
  font-family: Lato !important;
  padding: 15px 15px 0 15px;
  width: 1250px;
}

.MuiDataGrid-root .MuiDataGrid-main {
  background-color: white;
}

.MuiDataGrid-root .MuiDataGrid-main,
.MuiDataGrid-root .MuiDataGrid-footerContainer {
  border-radius: 4px;
}

.MuiDataGrid-viewport {
  /* overflow: scroll; */
}

.MuiDataGrid-columnHeaderWrapper,
.MuiDataGrid-footerContainer {
  background-color: white;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-radius: 4px;
}

.MuiDataGrid-root
  .MuiDataGrid-columnHeaderTitleContainer
  .MuiDataGrid-columnHeaderTitle {
  overflow: visible;
  text-overflow: unset;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: rgb(89, 116, 138);
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-row .MuiDataGrid-cell {
  min-width: 175px !important;
  flex-grow: 1;
  /* min-width: fit-content !important; */
  max-width: none !important;
  color: rgb(76, 88, 98);
  font-size: 14px;
}

.MuiDataGrid-columnHeader[data-field='kitStatus'],
.MuiDataGrid-cell[data-field='kitStatus'] {
  flex-grow: 2;
  min-width: 350px !important;
}

.MuiDataGrid-columnHeader[data-field='kitId'],
.MuiDataGrid-cell[data-field='kitId'] {
  flex-grow: 2;
  min-width: 120px !important;
}

.MuiDataGrid-columnHeader[data-field='trackingPatient'],
.MuiDataGrid-columnHeader[data-field='trackingLab'],
.MuiDataGrid-cell[data-field='trackingPatient'],
.MuiDataGrid-cell[data-field='trackingLab'] {
  min-width: 210px !important;
}

.MuiDataGrid-root .MuiDataGrid-renderingZone .MuiDataGrid-cell {
  padding-left: 17px;
  border-bottom: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
  display: none;
}

.MuiDataGrid-renderingZone .MuiDataGrid-row[role='row']:nth-child(odd) {
  background-color: #f7f9fb;
}

.MuiDataGrid-renderingZone .MuiDataGrid-row[role='row']:nth-child(even) {
  background-color: #ecf1f5;
}

.patientTable-patientButton {
  background-color: transparent;
  border: none;
}

.patientTable-patientButton:hover {
  cursor: pointer;
}

.patientTable-patientButton,
.patientTable-trackingLink {
  color: rgb(76, 88, 98);
  font-size: 14px;
  font-family: Lato;
}
