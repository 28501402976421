.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
  }
  
  .modal-content-pending-changes {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    width: 470px;
  }
  
  .modal-content h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 10px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }